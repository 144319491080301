import React from 'react';
import PropTypes from 'prop-types';

const objectToInformation = (obj = {}, depth = 0) => {
  const indent = '\t'.repeat(depth);
  let result = '';

  Object.keys(obj || {}).forEach((key) => {
    const line = value => `\n${indent}- ${key}: ${value}`;
    const property = obj[key];
    if (typeof property === 'object') {
      result += line(`\n${objectToInformation(property, depth + 1)}`);
    } else {
      result += line(property);
    }
  });

  return result;
};

function DebugInfo({ children, ...info }) {
  const { shop_id: shopId, scope, version } = info;
  const comment = objectToInformation({ shopId, scope, version });
  const html = `<!--${comment}\n-->`;
  return (
    <div>
      <span
        data-debug
        dangerouslySetInnerHTML={{ __html: html }} // eslint-disable-line react/no-danger
      />
      {children}
    </div>
  );
}

DebugInfo.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default DebugInfo;
